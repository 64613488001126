import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PortfolioCategory.css';

// Slugify function
const slugify = (name) => {
  return name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
};

// Sample data for demonstration
const allProjects = {
  apartment: [
    { id: 1, name: 'Akshar Priva', images: ['/data/apartments/akshar priva vadodara/ap1.jpg'] },
    { id: 2, name: 'Astha Vinayak', images: ['/data/apartments/astha vinayak v.v nagar/av1.jpeg'] },
    { id: 3, name: 'Divine Lakemont', images: ['/data/apartments/divine lakemont vadodara/dv2.jpg'] },
    { id: 4, name: 'Elite Heights', images: ['/data/apartments/elite heights vadodara/eh1.jpg'] },
    { id: 5, name: 'Green Plams', images: ['/data/apartments/green palms vadodara/gp1.jpg'] },
    { id: 6, name: 'Hiya Harmony', images: ['/data/apartments/hiya harmony vadodara/hh1.jpg'] },
    { id: 7, name: 'Hiya Horizon', images: ['/data/apartments/hiya horizon vadodara/hhh1.jpg'] },
    { id: 8, name: 'Kishan Sapphire', images: ['/data/apartments/kishan sapphire vadodara/ks1.jpg'] },
    { id: 9, name: 'Kishan26', images: ['/data/apartments/kishan26 vadodara/k1.jpg']},
    { id: 10, name: 'Manorath-56', images: ['/data/apartments/manorath-56/m1.jpg'] },
    { id: 11, name: 'Samruddhi Satvik', images: ['/data/apartments/samruddhi satvik vadodara/ss1.jpg'] },
    { id: 12, name: 'Shiv Arcade', images: ['/data/apartments/shiv arcade/sa1.jpg'] },
    { id: 13, name: 'Shiv Orbit', images: ['/data/apartments/shiv orbit/sa1.jpg']},
    { id: 14, name: 'The Solotaire', images: ['/data/apartments/the solitaire vadodara/ts1.jpg'] },
    { id: 15, name: 'The Vista', images: ['/data/apartments/the vista/tv1.jpg'] },
    { id: 16, name: 'Unity Lifestyle', images: ['/data/apartments/unity lifestyle vadodara/ul1.jpg']},
    
  ],
  bungalow: [
    { id: 1, name: 'Aakruti Villa', images: ['/data/bungalow/aakaruti villa vadodara/av1.png'] },
    { id: 2, name: 'Aarna Villa', images: ['/data/bungalow/aarna villa vadodara/aav1.jpeg'] },
    { id: 3, name: 'Aashiyana Park', images: ['/data/bungalow/aashiyana park vadodara/ap1.jpg'] },
    { id: 4, name: 'Al Madinah', images: ['/data/bungalow/al madinah vadodara/am1.jpg'] },
    { id: 5, name: 'Atriya Villa', images: ['/data/bungalow/atriya villa vadodara/at1.jpg'] },
    { id: 6, name: 'Dream 24', images: ['/data/bungalow/dream 24 vadodara/d1.jpg']},
    { id: 7, name: 'Lotus Lifestyle', images: ['/data/bungalow/lotus lifestyle vadodara/ll1.jpg']},
    { id: 8, name: 'NX Lifestyle', images: ['/data/bungalow/nx lifestyle vadodara/nx1.jpg'] },
    { id: 9, name: 'Parkfields ', images: ['/data/bungalow/parkfields vadodara/pf1.jpg'] },
    { id: 10, name: 'Parkplush', images: ['/data/bungalow/parkplush/pp1.jpg'] },
    { id: 11, name: 'Parkstreet', images: ['/data/bungalow/parkstreet vadodara/ps1.jpeg'] },
    { id: 12, name: 'Satva Sahaj Prime', images: ['/data/bungalow/satva sahaj prime vadodara/sp_snp_01.jpg'] },
    { id: 13, name: 'Vikram Villa', images: ['/data/bungalow/Vikrambhai residence africa/v1.jpg'] },
  ],
  commercialspace: [
    { id: 1, name: 'Foodplaza-POR', images: ['/data/commercial/foodplaza por/fp1.jpg'] },
    { id: 2, name: 'Ikrambhai Industries', images: ['/data/commercial/ikrambhai/i1.jpg'] },
    { id: 3, name: 'Urban Inspirom', images: ['/data/commercial/inspirom/ins1.jpg'] },
  ],
  hotel: [
    { id: 1, name: 'Krishna Hotel-Vadodara', images: ['/data/hotel/krishna hotel vadodara/k1.jpg']},
    { id: 2, name: 'Royal Hotel-Rajpipla', images: ['/data/hotel/royal hotel rajpipla/rh1.jpeg'] },
  ],
  industrial: [
    { id: 1, name: 'Goel Glass', images: ['/data/industrial/goel glass vadodara/gg1.jpg'] },
    // // { id: 2, name: 'J D Lotta', images: [demo, demo], description: 'Green park with recreational facilities.' },
    // { id: 3, name: 'Tarangbhai Industries', images: [demo, demo], description: 'Engineering marvel for urban connectivity.' },
    { id: 4, name: 'Tarapur MIDC', images: ['/data/industrial/MIDC/tm1.jpg'] },
    // { id: 5, name: 'Tarapur Industries', images: [demo, demo], description: 'Engineering marvel for urban connectivity.' },
  ],
  school: [
    { id: 1, name: 'Shree Techno Chetan School,Patan', images: ['/data/school/shree techno chetan school patan,mehsana/stc1.jpg'] },
  ],
};

function CategoryPage() {
  const { category } = useParams();
  const navigate = useNavigate();
  const projects = allProjects[category] || [];

  const handleProjectClick = (projectName) => {
    const projectSlug = slugify(projectName);
    navigate(`/portfolio/${category}/${projectSlug}`);
  };

  return (
    <div className="category-page">
      <h2 className="category-header">{category.charAt(0).toUpperCase() + category.slice(1)} Projects</h2>
      <div className="project-grid">
        {projects.map((project) => (
          <div
            key={project.id}
            className="project-card"
            onClick={() => handleProjectClick(project.name)}
          >
            <img src={project.images[0]} alt={project.name} className="project-image" />
            <h3 className="project-name">{project.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoryPage;

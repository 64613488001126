import React from 'react';
import { useParams } from 'react-router-dom';
import './ProjectPage.css';

const slugify = (name) => {
  return name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
};

// Sample data for demonstration
const allProjects = {
  apartment: [
    { id: 1, name: 'Akshar Priva', images: ['/data/apartments/akshar priva vadodara/ap1.jpg','/data/apartments/akshar priva vadodara/ap2.jpg'] },
    { id: 2, name: 'Astha Vinayak', images: ['/data/apartments/astha vinayak v.v nagar/av1.jpeg','/data/apartments/astha vinayak v.v nagar/av2.jpeg']},
    { id: 3, name: 'Divine Lakemont', images: ['/data/apartments/divine lakemont vadodara/dv1.jpg','/data/apartments/divine lakemont vadodara/dv2.jpg','/data/apartments/divine lakemont vadodara/dv3.jpg',] },
    { id: 4, name: 'Elite Heights', images: ['/data/apartments/elite heights vadodara/eh1.jpg','/data/apartments/elite heights vadodara/eh2.jpg','/data/apartments/elite heights vadodara/eh3.jpg'] },
    { id: 5, name: 'Green Plams', images: ['/data/apartments/green palms vadodara/gp1.jpg','/data/apartments/green palms vadodara/gp2.jpg','/data/apartments/green palms vadodara/gp3.jpg',] },
    { id: 6, name: 'Hiya Harmony', images: ['/data/apartments/hiya harmony vadodara/hh1.jpg','/data/apartments/hiya harmony vadodara/hh2.jpg','/data/apartments/hiya harmony vadodara/hh3.jpg','/data/apartments/hiya harmony vadodara/hh4.jpg',] },
    { id: 7, name: 'Hiya Horizon', images: ['/data/apartments/hiya horizon vadodara/hhh1.jpg',] },
    { id: 8, name: 'Kishan Sapphire', images: ['/data/apartments/kishan sapphire vadodara/ks1.jpg','/data/apartments/kishan sapphire vadodara/ks2.jpg','/data/apartments/kishan sapphire vadodara/ks3.jpg','/data/apartments/kishan sapphire vadodara/ks4.jpg',] },
    { id: 9, name: 'Kishan26', images: ['/data/apartments/kishan26 vadodara/k1.jpg','/data/apartments/kishan26 vadodara/k2.jpg',] },
    { id: 10, name: 'Manorath-56', images: ['/data/apartments/manorath-56/m1.jpg','/data/apartments/manorath-56/m2.jpg',] },
    { id: 11, name: 'Samruddhi Satvik', images: ['/data/apartments/samruddhi satvik vadodara/ss1.jpg','/data/apartments/samruddhi satvik vadodara/ss2.jpg','/data/apartments/samruddhi satvik vadodara/ss3.jpg','/data/apartments/samruddhi satvik vadodara/ss4.jpg',] },
    { id: 12, name: 'Shiv Arcade', images: ['/data/apartments/shiv arcade/sa1.jpg','/data/apartments/shiv arcade/sa2.jpg','/data/apartments/shiv arcade/sa3.jpg','/data/apartments/shiv arcade/sa4.jpg','/data/apartments/shiv arcade/sa5.jpg','/data/apartments/shiv arcade/sa6.jpg',] },
    { id: 13, name: 'Shiv Orbit', images: ['/data/apartments/shiv orbit/sa1.jpg','/data/apartments/shiv orbit/sa2.jpg','/data/apartments/shiv orbit/sa3.jpg','/data/apartments/shiv orbit/sa4.jpg','/data/apartments/shiv orbit/sa5.jpg','/data/apartments/shiv orbit/sa6.jpg','/data/apartments/shiv orbit/sa7.jpg','/data/apartments/shiv orbit/sa8.jpg','/data/apartments/shiv orbit/sa9.jpg','/data/apartments/shiv orbit/sa10.jpg','/data/apartments/shiv orbit/sa11.jpg','/data/apartments/shiv orbit/sa12.jpg',] },
    { id: 14, name: 'The Solotaire', images: ['/data/apartments/the solitaire vadodara/ts1.jpg','/data/apartments/the solitaire vadodara/ts2.jpg','/data/apartments/the solitaire vadodara/ts3.jpg',] },
    { id: 15, name: 'The Vista', images: ['/data/apartments/the vista/tv1.jpg','/data/apartments/the vista/tv2.jpg','/data/apartments/the vista/tv3.jpg',]},
    { id: 16, name: 'Unity Lifestyle', images: ['/data/apartments/unity lifestyle vadodara/ul1.jpg','/data/apartments/unity lifestyle vadodara/ul2.jpg','/data/apartments/unity lifestyle vadodara/ul3.jpg',] },
  ],
  bungalow: [
    { id: 1, name: 'Aakruti Villa', images: ['/data/bungalow/aakaruti villa vadodara/av1.png','/data/bungalow/aakaruti villa vadodara/av2.png'] },
    { id: 2, name: 'Aarna Villa', images: ['/data/bungalow/aarna villa vadodara/aav1.jpeg','/data/bungalow/aarna villa vadodara/aav2.jpg','/data/bungalow/aarna villa vadodara/aav3.jpg','/data/bungalow/aarna villa vadodara/aav4.jpg','/data/bungalow/aarna villa vadodara/aav5.jpg','/data/bungalow/aarna villa vadodara/aav6.jpg','/data/bungalow/aarna villa vadodara/aav7.jpeg'] },
    { id: 3, name: 'Aashiyana Park', images: ['/data/bungalow/aashiyana park vadodara/ap1.jpg','/data/bungalow/aashiyana park vadodara/ap2.jpg'] },
    { id: 4, name: 'Al Madinah', images: ['/data/bungalow/al madinah vadodara/am1.jpg','/data/bungalow/al madinah vadodara/am2.jpg','/data/bungalow/al madinah vadodara/am3.jpg','/data/bungalow/al madinah vadodara/am4.jpg'] },
    { id: 5, name: 'Atriya Villa', images: ['/data/bungalow/atriya villa vadodara/at1.jpg','/data/bungalow/atriya villa vadodara/at2.jpg','/data/bungalow/atriya villa vadodara/at3.jpg','/data/bungalow/atriya villa vadodara/at4.jpg','/data/bungalow/atriya villa vadodara/at5.jpg'] },
    { id: 6, name: 'Dream 24', images: ['/data/bungalow/dream 24 vadodara/d1.jpg','/data/bungalow/dream 24 vadodara/d2.jpg','/data/bungalow/dream 24 vadodara/d3.jpg','/data/bungalow/dream 24 vadodara/d6.jpg','/data/bungalow/dream 24 vadodara/d4.jpg','/data/bungalow/dream 24 vadodara/d5.jpg'] },
    { id: 7, name: 'Lotus Lifestyle', images: ['/data/bungalow/lotus lifestyle vadodara/ll1.jpg','/data/bungalow/lotus lifestyle vadodara/ll2.jpg','/data/bungalow/lotus lifestyle vadodara/ll3.jpg','/data/bungalow/lotus lifestyle vadodara/ll4.jpg','/data/bungalow/lotus lifestyle vadodara/ll5.jpg','/data/bungalow/lotus lifestyle vadodara/ll6.jpg'] },
    { id: 8, name: 'NX Lifestyle', images: ['/data/bungalow/nx lifestyle vadodara/nx1.jpg','/data/bungalow/nx lifestyle vadodara/nx2.jpg','/data/bungalow/nx lifestyle vadodara/nx3.jpg','/data/bungalow/nx lifestyle vadodara/nx4.jpg','/data/bungalow/nx lifestyle vadodara/nx5.jpg'] },
    { id: 9, name: 'Parkfields ', images: ['/data/bungalow/parkfields vadodara/pf1.jpg','/data/bungalow/parkfields vadodara/pf2.jpg'] },
    { id: 10, name: 'Parkplush', images: ['/data/bungalow/parkplush/pp1.jpg','/data/bungalow/parkplush/pp2.jpg'] },
    { id: 11, name: 'Parkstreet', images: ['/data/bungalow/parkstreet vadodara/ps1.jpeg','/data/bungalow/parkstreet vadodara/ps2.jpeg','/data/bungalow/parkstreet vadodara/ps3.jpeg'] },
    { id: 12, name: 'Satva Sahaj Prime', images: ['/data/bungalow/satva sahaj prime vadodara/sp_snp_01.jpg','/data/bungalow/satva sahaj prime vadodara/sp_snp_02.jpg','/data/bungalow/satva sahaj prime vadodara/sp_snp_03.jpg'] },
    { id: 13, name: 'Vikram Villa', images: ['/data/bungalow/Vikrambhai residence africa/v1.jpg','/data/bungalow/Vikrambhai residence africa/v2.jpg','/data/bungalow/Vikrambhai residence africa/v3.jpg','/data/bungalow/Vikrambhai residence africa/v4.jpg'] },
  ],
  commercialspace: [
    { id: 1, name: 'Foodplaza-POR', images: ['/data/commercial/foodplaza por/fp1.jpg','/data/commercial/foodplaza por/fp2.jpg','/data/commercial/foodplaza por/fp3.jpg'] },
    { id: 2, name: 'Ikrambhai Industries', images: ['/data/commercial/ikrambhai/i1.jpg','/data/commercial/ikrambhai/i2.jpg','/data/commercial/ikrambhai/i3.jpg','/data/commercial/ikrambhai/i4.jpg'] },
    { id: 3, name: 'Urban Inspirom', images: ['/data/commercial/inspirom/ins1.jpg','/data/commercial/inspirom/ins2.jpg'] },
  ],
  hotel: [
    { id: 1, name: 'Krishna Hotel-Vadodara', images: ['/data/hotel/krishna hotel vadodara/k1.jpg','/data/hotel/krishna hotel vadodara/k2.jpg','/data/hotel/krishna hotel vadodara/k3.jpg']},
    { id: 2, name: 'Royal Hotel-Rajpipla', images: ['/data/hotel/royal hotel rajpipla/rh1.jpeg'] },
  ],
  industrial: [
    { id: 1, name: 'Goel Glass', images: ['/data/industrial/goel glass vadodara/gg1.jpg','/data/industrial/goel glass vadodara/gg2.jpg','/data/industrial/goel glass vadodara/gg3.jpg'] },
    // { id: 2, name: 'J D Lotta', images: [demo, demo], description: 'Green park with recreational facilities.' },
    // { id: 3, name: 'Tarangbhai Industries', images: [demo, demo], description: 'Engineering marvel for urban connectivity.' },
    { id: 4, name: 'Tarapur MIDC', images: ['/data/industrial/MIDC/tm1.jpg','/data/industrial/MIDC/tm2.jpg'] },
    // { id: 5, name: 'Tarapur Industries', images: [demo, demo], description: 'Engineering marvel for urban connectivity.' },
  ],
  school: [
    { id: 1, name: 'Shree Techno Chetan School,Patan', images: ['/data/school/shree techno chetan school patan,mehsana/stc1.jpg','/data/school/shree techno chetan school patan,mehsana/stc2.jpg'] },
  ],
};

function ProjectPage() {
  const { category, projectSlug } = useParams();
  const projects = allProjects[category] || [];
  const project = projects.find((proj) => slugify(proj.name) === projectSlug);

  if (!project) {
    return <div>Project not found.</div>;
  }

  return (
    <div className="project-page">
      <h2 className="project-title">{project.name}</h2>
      <p className="project-description">{project.description}</p>
      <div className="project-images">
        {project.images.map((image, index) => (
          <img key={index} src={image} alt={`${project.name} ${index + 1}`} className="project-image" />
        ))}
      </div>
    </div>
  );
}

export default ProjectPage;

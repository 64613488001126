import React, { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Footer from './components/Footer';
import Header from './components/Header';
import logo from '../src/assets/logo-transparent.png'
import CategoryPage from './components/PortfolioCategory';
import ProjectPage from './components/ProjectPage';

import './Loading.css';

// Lazy-loaded components
const Hero = React.lazy(() => import('./components/Hero'));
const About = React.lazy(() => import('./components/About'));
const Services = React.lazy(() => import('./components/Services'));
const Portfolio = React.lazy(() => import('./components/Portfolio'));
const PortfolioCategory = React.lazy(() => import('./components/PortfolioCategory'));
const Contact = React.lazy(() => import('./components/Contact'));

function App() {
  const location = useLocation();

  // Page transition animation settings
  const pageTransition = {
    initial: { opacity: 0, y: 50, scale: 0.95 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, y: -50, scale: 0.95 },
    transition: { duration: 0.6, ease: 'easeInOut' },
  };

  return (
    <div className="content">
      <Header />
      <AnimatePresence mode="wait">
        <Suspense
          fallback={
            <div className="loading">
              <div className="rubiks-cube">
                <div className="rubiks-face front">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="rubiks-face right">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="rubiks-face back">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="rubiks-face left">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="rubiks-face top">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="rubiks-face bottom">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
              </div>
            </div>
          }
        >
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <motion.div {...pageTransition}>
                  <Hero />
                </motion.div>
              }
            />
            <Route
              path="/about"
              element={
                <motion.div {...pageTransition}>
                  <About />
                </motion.div>
              }
            />
            <Route
              path="/services"
              element={
                <motion.div {...pageTransition}>
                  <Services />
                </motion.div>
              }
            />
            <Route
              path="/portfolio"
              element={
                <motion.div {...pageTransition}>
                  <Portfolio />
                </motion.div>
              }
            />
            {/* Add routes for portfolio categories */}
            <Route
              path="/portfolio/:category"
              element={
                <motion.div {...pageTransition}>
                  <PortfolioCategory />
                </motion.div>
              }
            />
            <Route
              path="/contact"
              element={
                <motion.div {...pageTransition}>
                  <Contact />
                </motion.div>
              }
            />
            <Route path="/" element={<Portfolio />} />
            <Route path="/portfolio/:category" element={<CategoryPage />} />
            <Route path="/portfolio/:category/:projectSlug" element={<ProjectPage />} />
          </Routes>
        </Suspense>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
